import { Link } from '@/npm/next.js';
import { ChevronRightIcon } from '../Icons/index.js';
import { rootLinks } from '../Links.js';
import styles from './Page404.module.scss';

export const Page404 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>404</h1>
        <h2>Такой страницы не существует</h2>
        <Link href={rootLinks.main.url}>
          <span>на главную</span>
          <ChevronRightIcon />
        </Link>
      </div>
      <div className={styles.balls}>
        <div className={styles.greenBall}>
          <div></div>
        </div>
        <div className={styles.purpleBall}>
          <div></div>
        </div>
        <div className={styles.orangeBall}>
          <div></div>
        </div>
      </div>
    </div>
  );
};
